<template>
  <div>
    <search-form ref="form" :model="form" :count="4" label-width="auto" :collapse="false" @search="searchHandle">
      <search-item label="" prop="personName">
        <el-input v-model.trim="form.personName" clearable placeholder="企业名称" />
      </search-item>
      <search-item label="" prop="mobel">
        <el-input v-model.trim="form.mobel" clearable placeholder="用户名称" />
      </search-item>
      <search-item label="" prop="dispatchId">
        <el-select v-model="form.dispatchId" class="g-inp" clearable placeholder="日志类型">
          <el-option v-for="item in allDispatch" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </search-item>
      <search-item label="" prop="dispatchId">
        <el-select v-model="form.dispatchId" class="g-inp" clearable placeholder="操作日期">
          <el-option v-for="item in allDispatch" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </search-item>
    </search-form>
    <Table
      ref="tableList"
      :loading="loading"
      :show-selection-change="true"
      :table-cols="tableCols"
      :table-data="tableData"
      class="table-wrap"
      :pagination="pagination"
      :multiple-selection.sync="multipleSelection"
      @selectTime="selectTime"
      @sizeChange="sizeChange"
      @pageChange="pageChange"
    />
  </div>
</template>

<script>
// import { getDriverEscort, deleteDriverEscort, imporXls } from '@/api/driverEscort';
// import { exportByJson } from '@/utils';
export default {
  name: 'SystemLogs',
  data() {
    return {
      loading: false,
      form: {
        dataType: 1, // 数据类型-0-驾驶员，1-押运员
        personName: null, // 驾驶员姓名
        mobel: null, // 联系电话
        dispatchId: null, // 关联调度Id
        enterpriseId: null, // 企业id
        enterpriseName: null, // 企业name
      },
      allDispatch: [],
      tableCols: [
        { label: '账号', prop: 'personName' },
        { label: '用户姓名', prop: 'dispatchName' },
        { label: '操作时间', prop: 'mobel' },
        { label: '操作类型 ', prop: 'idNumber' },
        { label: '日志明细', prop: 'createBy' },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },

  created() {
    // this.getList();
  },

  methods: {
    // 调度列表
    getDispatch() {
      this.allDispatch = [
        { name: '张三', id: 1 },
        { name: '李四', id: 2 },
      ];
    },
    // getList() {
    //   this.loading = true;
    //   const params = {
    //     ...this.form,
    //     size: this.pagination.size,
    //     current: this.pagination.page,
    //     // orders: [{ asc: true ,column:''}],
    //   };
    //   getDriverEscort(params).then(res => {
    //     this.tableData = res.records || [];
    //     this.pagination = {
    //       page: res.current,
    //       size: res.size,
    //       total: res.total,
    //     };
    //   }).finally(() => {
    //     this.loading = false;
    //   });
    // },
    searchHandle() {
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    selectTime(time) {

    },
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      // const params = {
      //   idList: this.multipleSelection.map(d => d.id) || [],
      // };
      // deleteDriverEscort(params).then(res => {
      //   if (res) {
      //     this.$baseMessage('删除成功!', 'success');
      //     this.getList();
      //   }
      // });
    },
    imporXls() {
      // const params = {
      //   tpVehicles: this.multipleSelection,
      // };
      // imporXls(params).then(res => {
      //   if (res) {
      //     this.$baseMessage('导入成功!', 'success');
      //   }
      // });
    },
    onExport() {
      // const params = {
      //   ...this.form,
      // };
      // getDriverEscort(params).then(res => {
      //   const header = ['姓名', '关联调度', '联系电话', '身份证号码', '从业资格证有效期', '驾驶证有效期', '创建人', '创建时间', '更新人', '更新时间'];
      //   const exportData = res.records.map((item) => {
      //     return {
      //       personName: item.personName,
      //       dispatchName: item.dispatchName,
      //       mobel: item.mobel,
      //       idNumber: item.idNumber,
      //       createBy: item.createBy,
      //       createDate: item.createDate,
      //       updateBy: item.updateBy,
      //       updateDate: item.updateDate,
      //     };
      //   });
      //   exportByJson(exportData, header, '车辆列表.xlsx');
      // }).catch(() => {
      //   this.$baseMessage('导出失败', 'error');
      // });
    },
  },
};

</script>
